import React, { useState, useContext } from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { ExpandColumn, Subtable } from "./SubTabla"; // Ajusta la ruta al archivo de componente adecuada
import {
    renderAsyncSelectField,
    renderField,
    renderSwitch,
    renderFieldCheck,
    renderSwitchColum,
    renderSwitchClass,
} from '../../../Utils/renderField/renderField';
import {
    required,
    composeValidators,
    maxLength,
} from '../../../../../utility/validation';
import Table from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import { paginate } from '../../../../../utility/utils';
import InputSelect from '../../../Utils/InputSelect';
import {
    TYPE_BENEFICIARY_OPTIONS,
    LEVEL_BENEFICIARY_OPTIONS,
    LEVEL_BENEFICIARY,
    GRADE_YOUNG_OPTIONS,
    GRADE_KID_OPTIONS,
    ACTIVE,
    DESERTED,
    NOT_ENTER,
    GRADUATED,
    INACTIVE,
    ABANDONMENT,
    GRADUATED_THEORETICAL,
    GRADUATED_LEVELING,
} from '../../../../../utility/constants';
import _ from 'lodash';
import StudentsModal from './StudentsModal';
import './group_students.css';
import { api } from 'api';
import UserContext from '../../../../context/UserContext';


const STATUS_OPTIONS_TERICAL = [
    { value: ACTIVE, label: 'Activo', isDisabled: false },
    { value: NOT_ENTER, label: 'No ingresó', isDisabled: false },
    { value: DESERTED, label: 'Desertado', isDisabled: false },
    { value: GRADUATED, label: 'Egresado', isDisabled: false },
    { value: INACTIVE, label: 'Inactivo', isDisabled: false },
    { value: GRADUATED_THEORETICAL, label: 'Egresado Teórico', isDisabled: false },
];


const STATUS_OPTIONS_LEVELING = [
    { value: ACTIVE, label: 'Activo', isDisabled: false },
    { value: NOT_ENTER, label: 'No ingresó', isDisabled: false },
    { value: DESERTED, label: 'Desertado', isDisabled: false },
    { value: GRADUATED, label: 'Egresado', isDisabled: false },
    { value: INACTIVE, label: 'Inactivo', isDisabled: false },
    { value: GRADUATED_LEVELING, label: 'Egresado Nivelación', isDisabled: false },
];

const STATUS_PRACTICE_OPTIONS = [
    { value: ACTIVE, label: 'Activo', isDisabled: false },
    { value: NOT_ENTER, label: 'No ingresó', isDisabled: false },
    { value: ABANDONMENT, label: 'Abandono', isDisabled: false },
    { value: GRADUATED, label: 'Egresado', isDisabled: false },
    { value: INACTIVE, label: 'Inactivo', isDisabled: false },
];


const GroupStudentForm = (props) => {
    const { onSubmit, view, initialValues, groupId, isCreated } = props;
    const [expandedRow, setExpandedRow] = useState(null);
    const { asyncOptions: asyncSchool } = useAsyncOptions('school');
    const { user } = useContext(UserContext);
    const isExpandableRow = () => true;
    return (
        <Form initialValues={initialValues} onSubmit={onSubmit}>
            {({
                handleSubmit,
                submitting,
                values: {
                    beneficiaries = [],
                    status = true,
                    name = '',
                    code = '',
                    school = null,
                    modal = false,
                    page = 1,
                },
                form,
            }) => {
                props.handleDataChange({
                    beneficiaries: beneficiaries,
                    status: status,
                    modal: modal,
                    name: name,
                    code: code,
                    school: school,
                });
                const removeStudent = async (id) => {
                    const body = { id_student: id };
                    try {
                        if (view) {
                            await api.post(
                                `group_beneficiaries/${groupId}/unset`,
                                body
                            );
                        }
                        let copyBeneficiaries = [...beneficiaries];
                        copyBeneficiaries = copyBeneficiaries.filter(
                            (item) => item.id !== id
                        );
                        form.change('beneficiaries', copyBeneficiaries);
                    } catch (e) { }
                };

                const updateStatus = (id, status) => {
                    let copyBeneficiaries = [...beneficiaries];
                    const beneficiary = _.find(copyBeneficiaries, { id: id });
                    if (beneficiary && beneficiary.status !== status) {
                        copyBeneficiaries = copyBeneficiaries.map((item) => {
                            if (item.id === id) {
                                item.status = status;
                                item.status_updated = true;
                            }
                            return item;
                        });

                        form.change('beneficiaries', copyBeneficiaries);
                    }
                };

                const updatePracticeStatus = (id, status_practice) => {
                    let copyBeneficiaries = [...beneficiaries];
                    const beneficiary = _.find(copyBeneficiaries, { id: id });
                    if (beneficiary && beneficiary.status_practice !== status_practice) {
                        copyBeneficiaries = copyBeneficiaries.map((item) => {
                            if (item.id === id) {
                                item.status_practice = status_practice;
                                item.status_practice_updated = true;
                            }
                            return item;
                        });

                        form.change('beneficiaries', copyBeneficiaries);
                    }
                };

                const updateLevelingStatus = (id, status_leveling) => {
                    let copyBeneficiaries = [...beneficiaries];
                    const beneficiary = _.find(copyBeneficiaries, { id: id });
                    if (beneficiary && beneficiary.status_leveling !== status_leveling) {
                        copyBeneficiaries = copyBeneficiaries.map((item) => {
                            if (item.id === id) {
                                item.status_leveling = status_leveling;
                                item.status_leveling_updated = true;
                            }
                            return item;
                        });

                        form.change('beneficiaries', copyBeneficiaries);
                    }
                };

                const updateStatusTeoretical = (id, theorical_status) => {
                    let copyBeneficiaries = [...beneficiaries];

                    const beneficiary = _.find(copyBeneficiaries, { id: id });
                    if (beneficiary && beneficiary.theorical_status !== theorical_status) {
                        copyBeneficiaries = copyBeneficiaries.map((item) => {
                            if (item.id === id) {
                                item.theorical_status = theorical_status;
                                item.theorical_status_updated = true;
                            }
                            return item;
                        });

                        form.change('beneficiaries', copyBeneficiaries);
                    }
                };

                const updateStatusPractice = (id, practice_status) => {
                    let copyBeneficiaries = [...beneficiaries];

                    const beneficiary = _.find(copyBeneficiaries, { id: id });
                    if (beneficiary && beneficiary.practice_status !== practice_status) {
                        copyBeneficiaries = copyBeneficiaries.map((item) => {
                            if (item.id === id) {
                                item.practice_status = practice_status;
                                item.practice_status_updated = true;
                            }
                            return item;
                        });

                        form.change('beneficiaries', copyBeneficiaries);
                    }
                };

                const updateStatusLeveling = (id, leveling_status) => {
                    let copyBeneficiaries = [...beneficiaries];

                    const beneficiary = _.find(copyBeneficiaries, { id: id });
                    if (beneficiary && beneficiary.leveling_status !== leveling_status) {
                        copyBeneficiaries = copyBeneficiaries.map((item) => {
                            if (item.id === id) {
                                item.leveling_status = leveling_status;
                                item.leveling_status_updated = true;
                            }
                            return item;
                        });

                        form.change('beneficiaries', copyBeneficiaries);
                    }
                };


                return (
                    <form
                        name="GroupBeneficiariesForm"
                        className="form-validate mb-lg"
                        onSubmit={handleSubmit}
                    >
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="id">Código</label>
                                <Field
                                    name="code"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="type">Nombre del grupo</label>
                                <Field
                                    name="name"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="type">Colegio</label>
                                <Field
                                    name="school"
                                    component={renderAsyncSelectField}
                                    loadOptions={asyncSchool}
                                    isClearable={true}
                                    valueKey="id"
                                    labelKey="name"
                                    type="text"
                                    className="w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="status">Estado del grupo</label>
                                <Field
                                    name="status"
                                    component={renderSwitch}
                                    label={status ? 'Activo' : 'Inactivo'}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-4">
                            <div>
                                <h2 className="table-title-group mt-4 mt-md-0">
                                    Lista de beneficiarios
                                </h2>
                            </div>

                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => form.change('modal', true)}
                                disabled={view}
                            >
                                Agregar estudiantes
                            </button>
                        </div>
                        <div>
                            <Table
                                data={{
                                    results: paginate(beneficiaries, page),
                                    count: beneficiaries.length,
                                }}
                                onPageChange={(page) =>
                                    form.change('page', page)
                                }
                                page={page}
                                expandableRow={isExpandableRow}
                                expandComponent={Subtable}
                                expandColumnOptions={{
                                    expandColumnVisible: true,
                                    expandColumnComponent: ExpandColumn,
                                    columnWidth: 50,
                                }}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    width="7rem"
                                    isKey
                                    dataFormat={standardActions({
                                        erase: (id) => removeStudent(id),
                                        type: user.type,
                                    })}
                                >
                                    {/* Eliminar */}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="10rem"
                                    dataField="first_name"
                                    dataFormat={(value, row) => (
                                        <div
                                            style={{
                                                textDecoration: 'none',
                                                color: '#000000',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                expandedRow === row.id
                                                    ? setExpandedRow(null)
                                                    : setExpandedRow(row.id)
                                            }
                                        >
                                            {value}
                                        </div>
                                    )}
                                >
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="10rem"
                                    dataField="last_name"
                                    dataFormat={(value, row) => {
                                        return (
                                            <a
                                                href={`/#/beneficiary/${row.id}/update`}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: '#000000',
                                                }}
                                            >
                                                {value}
                                            </a>
                                        );
                                    }}
                                >
                                    Apellidos
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="8rem"
                                    dataField="type"
                                    dataFormat={(value) =>
                                        _.find(TYPE_BENEFICIARY_OPTIONS, {
                                            value: value,
                                        }).label
                                    }
                                >
                                    Tipo beneficiario
                                </TableHeaderColumn>
                                {
                                    <TableHeaderColumn
                                        width="8rem"
                                        dataField="level"
                                        dataAlign="center"
                                        dataFormat={(value, obj) => {
                                            const b_level = _.find(
                                                LEVEL_BENEFICIARY,
                                                {
                                                    value: value,
                                                }
                                            );
                                            const b_grade = _.find(
                                                [
                                                    ...GRADE_YOUNG_OPTIONS,
                                                    ...GRADE_KID_OPTIONS,
                                                ],
                                                {
                                                    value: obj.school_grade,
                                                }
                                            );
                                            return b_level && b_grade
                                                ? `${b_grade.label} | ${b_level.label}`
                                                : b_grade
                                                    ? `${b_grade.label}`
                                                    : '---';
                                        }}
                                    >
                                        Grado | Nivel
                                    </TableHeaderColumn>
                                }
                                <TableHeaderColumn width="1rem"></TableHeaderColumn>

                                {!isCreated && (
                                    <TableHeaderColumn
                                        width="10rem"
                                        dataField="status"
                                        dataFormat={(value, row) => {
                                            return (
                                                <InputSelect
                                                    customStyles={{
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 2000,
                                                        }),
                                                    }}
                                                    isSearchable={true}
                                                    menuPortalTarget={document.body}
                                                    placeholder="Estado"
                                                    onChange={(e) => {
                                                        updateStatus(row.id, e);
                                                        if (e == INACTIVE) {
                                                            updateStatusTeoretical(row.id, false);
                                                        }
                                                        else {
                                                            updateStatusTeoretical(row.id, true);
                                                        }                                                        
                                                    }}
                                                    options={STATUS_OPTIONS_TERICAL}
                                                    value={value || null}
                                                    disabled={view}
                                                />
                                            );
                                        }}
                                    >
                                        Estado Teórico
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn width="1rem"></TableHeaderColumn>
                                {!isCreated && (
                                    <TableHeaderColumn
                                        width="10rem"
                                        dataField="status_practice"
                                        dataFormat={(value, row) => {
                                            return (
                                                <InputSelect
                                                    customStyles={{
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 2000,
                                                        }),
                                                    }}
                                                    isSearchable={true}
                                                    menuPortalTarget={document.body}
                                                    placeholder="Estado"
                                                    onChange={(e) => {
                                                        updatePracticeStatus(row.id, e);
                                                        if (e == INACTIVE) {
                                                            updateStatusPractice(row.id, false);
                                                        }
                                                        else {
                                                            updateStatusPractice(row.id, true);
                                                        }     
                                                    }}
                                                    options={STATUS_PRACTICE_OPTIONS}
                                                    value={value || null}
                                                    disabled={view}
                                                />
                                            );
                                        }}
                                    >
                                        Estado Práctico
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn width="1rem"></TableHeaderColumn>
                                {!isCreated && (
                                    <TableHeaderColumn
                                        width="10rem"
                                        dataField="status_leveling"
                                        dataFormat={(value, row) => {
                                            return (
                                                <InputSelect
                                                    customStyles={{
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 2000,
                                                        }),
                                                    }}
                                                    isSearchable={true}
                                                    menuPortalTarget={document.body}
                                                    placeholder="Estado"
                                                    onChange={(e) => {
                                                        updateLevelingStatus(row.id, e);
                                                        if (e == INACTIVE) {
                                                            updateStatusLeveling(row.id, false);
                                                        }
                                                        else {
                                                            updateStatusLeveling(row.id, true);
                                                            (row.id, true);
                                                        }   
                                                    }}
                                                    options={STATUS_OPTIONS_LEVELING}
                                                    value={value || null}
                                                    disabled={view}
                                                />
                                            );
                                        }}
                                    >
                                        Estado Nivelación
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn width="2rem"></TableHeaderColumn>
                                {!isCreated && (
                                    <TableHeaderColumn
                                        width="5rem"
                                        dataField="theorical_status"
                                        dataFormat={(value, row) => (
                                            <div onClick={(e) => e.stopPropagation()}>
                                                {renderSwitch({
                                                    input: {
                                                        value: row.theorical_status,
                                                        onChange: (e) => {
                                                            updateStatusTeoretical(row.id, e);
                                                            if (e == false) {
                                                                updateStatus(row.id, INACTIVE);
                                                            }
                                                            else {
                                                                updateStatus(row.id, ACTIVE);
                                                            }
                                                        }
                                                    },
                                                    type: 'checkbox',
                                                    meta: { touched: false, error: '' },
                                                    disabled: view
                                                })}
                                            </div>
                                        )}
                                    >
                                        Teórico
                                    </TableHeaderColumn>
                                )}
                                {!isCreated && (
                                    <TableHeaderColumn
                                        width="5rem"
                                        dataField="practice_status"
                                        dataFormat={(value, row) => (
                                            <div onClick={(e) => e.stopPropagation()}>
                                                {renderSwitch({
                                                    input: {
                                                        value: row.practice_status,
                                                        onChange: (e) => {
                                                            updateStatusPractice(row.id, e);
                                                            if (e == false) {
                                                                updatePracticeStatus(row.id, INACTIVE);
                                                            } else {
                                                                updatePracticeStatus(row.id, ACTIVE);
                                                            }
                                                        }
                                                    },
                                                    type: 'checkbox',
                                                    meta: { touched: false, error: '' },
                                                    disabled: view
                                                })}
                                            </div>
                                        )}
                                    >
                                        Práctico
                                    </TableHeaderColumn>
                                )}
                                {!isCreated && (
                                    <TableHeaderColumn
                                        width="6rem"
                                        dataField="leveling_status"
                                        dataFormat={(value, row) => (
                                            <div onClick={(e) => e.stopPropagation()}>
                                                {renderSwitch({
                                                    input: {
                                                        value: row.leveling_status,
                                                        onChange: (e) => {
                                                            updateStatusLeveling(row.id, e);
                                                            if (e == false) {
                                                                updateLevelingStatus(row.id, INACTIVE);
                                                            }
                                                            else {
                                                                updateLevelingStatus(row.id, ACTIVE);
                                                            }
                                                        }
                                                    },
                                                    type: 'checkbox',
                                                    meta: { touched: false, error: '' },
                                                    disabled: view
                                                })}
                                            </div>
                                        )}
                                    >
                                        Nivelación
                                    </TableHeaderColumn>
                                )}
                            </Table>

                            {expandedRow !== null && (
                                <TableHeaderColumn
                                    width="100%"
                                    dataField="legal_guardians"
                                    dataFormat={(value) => (
                                        <NestedChildTable data={value} />
                                    )}
                                >
                                    Guardianes Legales
                                </TableHeaderColumn>
                            )}
                        </div>

                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            <Link
                                to="/group_beneficiaries"
                                className="btn btn-secondary align-self-center mt-4 m-md-0"
                            >
                                Regresar
                            </Link>
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary align-self-center"
                                    disabled={submitting}
                                >
                                    Guardar
                                </button>
                            )}
                        </div>
                        {modal && (
                            <StudentsModal
                                openModal={modal}
                                onCloseModal={() => form.change('modal', false)}
                                students={beneficiaries}
                            />
                        )}
                    </form>
                );
            }}
        </Form>
    );
};

export default GroupStudentForm;
